.NavLink {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    font-size: 2em;
}

.NavLinkActive {
    color: black;
    z-index: 1;
    overflow: hidden;
}

.NavLinkActive::before {
    content: '';
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background:white;

    z-index: -1;
}

@keyframes back {
    0%   { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

@keyframes colorshift {
    0%   { color: white; }
    100% { color: black; }
}
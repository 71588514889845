@import url(https://fonts.googleapis.com/css2?family=Oswald&family=Roboto+Condensed:wght@300&display=swap);
:root {
  --bg-color: rgba(240,205,7,1);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(240,205,7,1);
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: rgb(196, 88, 0);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(196, 88, 0);
}

html {
  background-color: rgba(240,205,7,1);
  background-color: var(--bg-color);
  scrollbar-color: rgb(196, 88, 0) rgba(240,205,7,1);
  scrollbar-color: rgb(196, 88, 0) var(--bg-color);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  width: 100%;

  display: grid;
  justify-items: center;
  align-items: center;

  background-color: rgba(240,205,7,1);

  background-color: var(--bg-color);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: transparent;
}

@media (min-width: 350px) {
  #root {
    font-size: 1rem;
  }
}

@media (min-width: 550px) {
  #root {
    font-size: 1.1rem;
  }
}
.LanguageButton_LanguageButton__3-nle {
    position: relative;

    padding: 0.3rem;

    margin: 0.25rem;

    width: 30px;
    height: 30px;
    width: clamp(25px, 3vw, 40px);
    height: clamp(25px, 3vw, 40px);

    border-radius: 50%;
    border: none;

    font-size: 0.65em;
    font-feature-settings: "smcp";
    font-variant: small-caps;

    cursor: pointer;

    background-color: rgb(255, 243, 188);

    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

    transition: box-shadow 100ms linear;
}

.LanguageButton_LanguageButton__3-nle:focus,
.LanguageButton_LanguageButton__3-nle:active {
    outline: none;
}

.LanguageButton_LanguageButton__3-nle:focus {
    box-shadow: 
        1px 1px 3px rgba(0, 0, 0, 0.2),
        0 0 0 0.25rem rgb(97, 163, 21);
}

@media (min-width: 550px) {
    .LanguageButton_LanguageButton__3-nle {
        font-size: 0.7em;
    }
}
:root {
    --color: rgb(119, 246, 255);
}

.MenuActivation_MenuActivation__INTwj {
    position: relative;

    margin: 0.25rem;

    height: 40px;
    width: 40px;
    width: clamp(40px, 5vw, 70px);
    height: clamp(40px, 5vw, 70px);

    border-radius: 50%;
    border: none;

    cursor: pointer;

    background-color: white;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

    transition: box-shadow 100ms linear;
}

.MenuActivation_MenuActivation__INTwj::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 50%;
    border: none;

    animation: MenuActivation_pulse__6vKG8 3s ease-in-out infinite;
}

.MenuActivation_MenuActivation__INTwj:focus,
.MenuActivation_MenuActivation__INTwj:active {
    outline: none;
}

.MenuActivation_MenuActivation__INTwj:focus {
    box-shadow: 
        2px 2px 3px rgba(0, 0, 0, 0.2),
        0 0 0 0.25rem rgb(60, 107, 7);
}

@keyframes MenuActivation_pulse__6vKG8 {
    0%      {box-shadow: 0 0 0 rgb(119, 246, 255);box-shadow: 0 0 0 var(--color);}
    50%     {box-shadow: 0 0 10px rgb(119, 246, 255);box-shadow: 0 0 10px var(--color);}
    100%    {box-shadow: 0 0 0 rgb(119, 246, 255);box-shadow: 0 0 0 var(--color);}
}
.NavLink_NavLink__3nALe {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    font-size: 2em;
}

.NavLink_NavLinkActive__17PV4 {
    color: black;
    z-index: 1;
    overflow: hidden;
}

.NavLink_NavLinkActive__17PV4::before {
    content: '';
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background:white;

    z-index: -1;
}

@keyframes NavLink_back__2adrK {
    0%   { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

@keyframes NavLink_colorshift__1H7yY {
    0%   { color: white; }
    100% { color: black; }
}
.NavButtons_NavButtons__g3h3O {
    position: fixed;

    bottom: 15px;
    right: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;

    z-index: 1000;

    transition:
        transform 300ms ease-in-out,
        opacity 300ms ease-in-out;
}

.NavButtons_NavButtonsOut__YIElO {
    transform: translateY(150%);
    opacity: 0;
}

.NavButtons_NavButtonsIn__16DYY {
    transform: translateY(0);
    opacity: 1;
}

@media (min-width: 720px) and (min-height: 650px) {
    .NavButtons_NavButtons__g3h3O {
        bottom: unset;
        top: clamp(5px, 3vh, 20px);
        right: 50%;

        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;

        transform: translateX(50%);
    }

    .NavButtons_NavButtonsOut__YIElO {
        transform: translateX(50%) translateY(-150%);
    }
    
    .NavButtons_NavButtonsIn__16DYY {
        transform: translateX(50%) translateY(0);
    }
}
.Nav_Nav__2KBhF {
    position: fixed;

    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;

    background-color: #a53f0f;

    transform: translateX(-100%);
    opacity: 0;
    transition: 
        transform 100ms ease-in-out,
        opacity 200ms linear;

    z-index: 20000;
}

.Nav_Nav__2KBhF button {
    content: '\00d7';
    position: fixed;
    bottom: 60px;
    right: 25px;

    background: transparent;
    border: none;
    border-radius: 50%;

    width: 1.5em;
    height: 1.5em;

    font-size: 3rem;
    color: white;
    font-weight: 600;

    cursor: pointer;

    transform-origin: center right;

    transition: box-shadow 150ms linear;
}

.Nav_Nav__2KBhF button:focus {
    outline: none;
    box-shadow: 0 0 0 0.5rem rgba(240,205,7,1);
}

.Nav_Active__3kiJh {
    animation: Nav_show__2s__J 100ms ease-in-out forwards;
}

.Nav_Active__3kiJh button {
    animation: Nav_bounce__3IS2C 1.4s ease-in-out forwards;
}

.Nav_Inactive__3n89b {
    animation: Nav_hide__-xlBA 100ms ease-in-out forwards;
}

.Nav_Inactive__3n89b button {
    display: none;
    pointer-events: none;
}

.Nav_Menu__did9s::before,
.Nav_Active__3kiJh::before {
    content: attr(data-title);
    font-size: 3em;
    text-transform: uppercase;
    color: white;
    padding: 0.5rem;
    margin-bottom: 3rem;
}

@keyframes Nav_show__2s__J {
    0%   { transform: translateX(-20%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes Nav_hide__-xlBA {
    0%   { transform: translateX(0); opacity: 1; }
    99%  { transform: translateX(0); opacity: 0; }
    100% { transform: translateX(-100%); }
}

@keyframes Nav_bounce__3IS2C {
    0%   { transform: scaleX(1) scaleY(1); }
    10%  { transform: scaleX(0.4) scaleY(1.6); }
    25%  { transform: scaleX(1.5) scaleY(0.5); }
    40%  { transform: scaleX(0.8) scaleY(1.2); }
    55%  { transform: scaleX(1) scaleY(1); }
    100% { transform: scaleX(1) scaleY(1); }
}

@media (min-width: 550px) {
    .Nav_Nav__2KBhF {
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        transform-origin: bottom center;
        transform: translateY(100%) scale(0);
    }

    .Nav_Nav__2KBhF button {
        --tX: translateX(50%);
        right: 50%;
        transform: translateX(50%);
        transform-origin: bottom center;
    }

    .Nav_Active__3kiJh {
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    @keyframes Nav_show__2s__J {
        0%      { transform: translateY(-20%); opacity: 0; }
        100%    { transform: translateX(0); opacity: 1; }
    }

    @keyframes Nav_bounce__3IS2C {
        0%   { transform: var(--tX) scaleY(1); }
        10%  { transform: var(--tX) scaleY(0.4) scaleX(1.6); }
        25%  { transform: var(--tX) scaleY(1.5) scaleX(0.5); }
        40%  { transform: var(--tX) scaleY(0.8) scaleX(1.2); }
        55%  { transform: var(--tX) scaleY(1) scaleX(1); }
        100% { transform: var(--tX) scaleY(1) scaleX(1); }
    }
}
.Modal_OffScreen__1IVUv {
    position: fixed;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    transform: translate(-100%, -100%);
    opacity: 0;
    pointer-events: none;
    display: none;
}

.Modal_Modal__KtEB3 {
    position: fixed;

    bottom: 0;
    left: 0;

    height: max(150vw, 150vh);
    width: max(150vw, 150vh);

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);

    z-index: 2000;
}

.Modal_ModalIn__1g_Wf {
    animation: Modal_orbit__uvOZl 500ms ease-out forwards;
}

.Modal_ModalOut__2_pzY {
    animation: Modal_deorbit__3iXKY 500ms ease-in forwards;
}

.Modal_ModalClose__1rWfc {
    position: fixed;
    top: 20px;
    right: 20px;
    
    height: 50px;
    width: 50px;

    border: none;
    border-radius: 50%;

    background: rgb(255, 68, 35);

    cursor: pointer;

    z-index: 2500;

    opacity: 0;

    box-shadow: 
        0 2px 10px rgba(0, 0, 0, 0.185),
        0 0 0 -5px rgb(255, 145, 0);

    transition: box-shadow 200ms linear;
}

.Modal_ModalCloseIn__t-s-q {
    animation: Modal_drop__2iLlz 500ms cubic-bezier(.71,1.65,.09,.79) forwards;
}

.Modal_ModalCloseOut__3dM6V {
    animation: Modal_pull__3Lsi5 500ms cubic-bezier(1,.05,0,-0.31) forwards;
}

.Modal_ModalClose__1rWfc:focus,
.Modal_ModalClose__1rWfc:active {
    outline: none;
    box-shadow: 
        0 0 0 5px rgba(146, 0, 0, 1),
        0 2px 10px rgba(0, 0, 0, 0.19);
}

.Modal_ModalClose__1rWfc::before,
.Modal_ModalClose__1rWfc::after {
    content: '';
    
    position: absolute;
    top: 50%;
    left: 50%;

    height: 5px;
    width: 35%;

    border-radius: 1em;

    background: white;
}

.Modal_ModalClose__1rWfc::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.Modal_ModalClose__1rWfc::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.Modal_ModalContent__2ifBY {
    position: fixed;
    bottom: 0;
    left: 0;
    
    width: 100vw;
    height: 100vh;

    display: grid;
    place-content: center;
    
    z-index: 2200;

    opacity: 0;
}

.Modal_ModalContentIn__159Rf {
    animation: Modal_contentIn__xX2oe 500ms 100ms ease-out forwards;
}

.Modal_ModalContentOut__2VmaE {
    animation: Modal_contentOut__o7gtj 500ms ease-in forwards;
}

@keyframes Modal_orbit__uvOZl {
    0%   { transform: translate(-100%, 100%); background-color: rgba(0, 0, 0, 0); }
    30%  { background-color: rgba(0, 0, 0, 0); }
    100% { transform: translate(-20%, 20%); background-color: rgba(0, 0, 0, 0.5); }
}

@keyframes Modal_deorbit__3iXKY {
    0%   { transform: translate(-20%, 20%); background-color: rgba(0, 0, 0, 0.5); }
    70%  { background-color: rgba(0, 0, 0, 0); }
    100% { transform: translate(-100%, 100%); background-color: rgba(0, 0, 0, 0); }
}

@keyframes Modal_contentIn__xX2oe {
    0%   { transform: translateY(-100%); opacity: 0; }
    30%  { opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes Modal_contentOut__o7gtj {
    0%   { transform: translateY(0); opacity: 1; }
    70%  { opacity: 0; }
    100% { transform: translateY(-100%); opacity: 0; }
}

@keyframes Modal_drop__2iLlz {
    0%   { transform: translateY(-400%); opacity: 0; }
    1%   { opacity: 1; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes Modal_pull__3Lsi5 {
    0%   { transform: translateY(0); opacity: 1; }
    99%  { opacity: 1; }
    100% { transform: translateY(-400%); opacity: 0; }
}
.MainStructure_MainStructure__2bHjb {
    background-color: white;

    position: relative;
    display: grid;

    grid-template: 'main';

    height: 100%;

    color: black;

    background: transparent;
}

.MainStructure_MainStructure__2bHjb main {
    padding-bottom: 3rem;
    max-width: 100vw;
}

@media (min-width: 550px) {
    .MainStructure_MainStructure__2bHjb {
        place-content: center;
    }

    .MainStructure_MainStructure__2bHjb main {
        padding-top: clamp(3rem, 10vw, 15rem);
        padding-bottom: 0;
    }
}
.AboutScreen_AboutScreen__2f3cG {
    display: grid;
    grid-template: 
        'titles'
        'content'
        'extra';
    grid-row-gap: 2em;
    row-gap: 2em;
    padding: 2em;

    z-index: 1;
}

.AboutScreen_Titles__3QMP0 {
    grid-area: titles;
    font-family: 'Oswald', sans-serif;
    display: flex;
    flex-direction: column;
    opacity: 0;

    animation: AboutScreen_show__3JXDp 300ms cubic-bezier(.41,-0.01,.33,1.26) forwards;
}

.AboutScreen_Titles__3QMP0 h1 {
    text-transform: uppercase;
    font-size: 2em;
}

.AboutScreen_Intro__1stMj {
    grid-area: content;
    animation: AboutScreen_show__3JXDp 300ms 30ms cubic-bezier(.41,-0.01,.33,1.26) forwards;
    opacity: 0;
}

.AboutScreen_ExtraContent__1MnvU {
    grid-area: extra;
    opacity: 0;
    animation: AboutScreen_show__3JXDp 300ms 60ms cubic-bezier(.41,-0.01,.33,1.26) forwards;
}

@keyframes AboutScreen_show__3JXDp {
    0%      {transform: translateY(50%); opacity: 0;}
    100%    {transform: translateY(0); opacity: 1;}
}

@media (min-width: 750px) {
    .AboutScreen_AboutScreen__2f3cG {
        grid-template:
            'titles titles'
            'content extra';
        grid-column-gap: 2em;
        -webkit-column-gap: 2em;
                column-gap: 2em;
        padding: 1em 10vw;
    }
}

@media (min-width: 1295px) {
    .AboutScreen_AboutScreen__2f3cG {
        grid-template:
            'titles content'
            'extra extra';
        padding: 1em 15vw;
        grid-template-columns: -webkit-max-content 1fr;
        grid-template-columns: max-content 1fr;
        grid-column-gap: 4em;
        -webkit-column-gap: 4em;
                column-gap: 4em;
    }

    .AboutScreen_Titles__3QMP0 {
        align-items: flex-end;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }

    .AboutScreen_Titles__3QMP0 h1,
    .AboutScreen_Titles__3QMP0 h3 {
        text-align: right;
    }

    .AboutScreen_Intro__1stMj {
        padding: 0.5em 0;
    }
}
.ProjectsScreen_ProjectsScreen__1Fy5H {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2em;
    z-index: 1;
    animation: ProjectsScreen_show__1jHF_ 300ms cubic-bezier(.41,-0.01,.33,1.26) forwards;
}

.ProjectsScreen_Title__30KA3 {
    font-family: 'Oswald', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
}

.ProjectsScreen_Intro__2-aH- {
    margin: 2em 0;
}

.ProjectsScreen_Cards__1AcU5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

@keyframes ProjectsScreen_show__1jHF_ {
    0%      {transform: translateY(-5%); opacity: 0;}
    100%    {transform: translateY(0); opacity: 1;}
}

@media (min-width: 550px) {
    .ProjectsScreen_ProjectsScreen__1Fy5H {
        align-items: center;
        padding: 1.5em clamp(2em, 10vw, 20em);
    }

    .ProjectsScreen_Intro__2-aH- {
        margin: 2em clamp(2em, 8vw, 20em);
    }
}
.Card_CardContainer__3mVlv {
    position: relative;
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-bottom: 2rem;
}

.Card_Card__1k9eP {
    background: white;

    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;

    position: relative;
    padding: 15em 5em;
    border-radius: 5px 5px 20px 5px;

    transition: transform 750ms cubic-bezier(1,-0.52,0,1.59);
    transform-style: preserve-3d;

    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.Card_CardFlip__1920b {
    transform: rotateY(.5turn);
}

.Card_CardFront__3PE7s,
.Card_CardBack__2DhEV {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    padding: 2rem;

    background-color: white;
    color: black;
    transform-style: preserve-3d;

    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

.Card_CardFront__3PE7s {
    border-radius: 5px 5px 20px 5px;
}

.Card_CardBack__2DhEV {
    display: grid;
    grid-template-rows: 1fr -webkit-min-content;
    grid-template-rows: 1fr min-content;
    grid-gap: 0.5rem;

    /* color: white;
    background-color: rgb(15, 141, 101); */
    border-radius: 5px 5px 5px 20px;
    transform: rotateY(.5turn);
}

.Card_CardBackTechnologies__22SgH {
    color: #8f8f8f;
}

.Card_CardFront__3PE7s a,
.Card_CardBack__2DhEV a {
    position: relative;
    display: block;
    color: rgb(255, 153, 0);
    text-decoration: none;
    padding-bottom: 0.5em;
    transition: transform 150ms ease-in-out;
}

.Card_CardFront__3PE7s a::before,
.Card_CardBack__2DhEV a::before {
    content: '→';
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-110%);
    opacity: 0;
    transition: 
        transform 150ms ease-in-out,
        opacity 150ms ease-in-out;
}

.Card_CardFront__3PE7s a:hover,
.Card_CardBack__2DhEV a:hover {
    transform: translateX(1.5rem);
    text-decoration: underline;
}

.Card_CardFront__3PE7s a:focus,
.Card_CardBack__2DhEV a:focus {
    outline: none;
    color: rgb(31, 109, 199);
    font-weight: 600;
}

.Card_CardFront__3PE7s a:hover::before,
.Card_CardBack__2DhEV a:hover::before {
    transform: translateX(-1.5rem);
    opacity: 1;
}

.Card_CardImageButton__n_IXp {
    width: 100%;
    height: 50%;

    background-color: rgb(49, 49, 49);

    position: relative;

    margin-bottom: 1rem;

    border: none;
    border-radius: 5px;

    cursor: pointer;

    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);

    overflow: hidden;
}

.Card_CardImageButton__n_IXp:focus {
    box-shadow: 0 0 0 5px black;
}

.Card_CardImage__vWZX7 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;
}

.Card_CardImageExpand__1rZUn {
    animation: Card_pull__2n7IO 150ms linear forwards;
}

.Card_CardImageContract__2esnp {
    animation: Card_pull__2n7IO 150ms linear backwards;
}

.Card_CardFrontTitle__2g1a4 {
    font-family: 'Oswald', sans-serif;
    margin: 0 0 0.5em 0;
}

.Card_CardFrontTitle__2g1a4,
.Card_CardFrontText__1H7EW,
.Card_CardFrontLink__Qvc-h,
.Card_CardImageButton__n_IXp,
.Card_CardBack__2DhEV p,
.Card_CardBack__2DhEV button {
    transform: translateZ(5rem);
}

@keyframes Card_pull__2n7IO {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-100%); }
}

@media (min-width: 550px) {
    .Card_Card__1k9eP {
        padding: 10em 5em;
    }

    .Card_CardContainer__3mVlv {
        margin: 1rem;
    }
}
.CardButton_ButtonPlus__25SZe,
.CardButton_ButtonMinus__13y8t {
    position: absolute;
    bottom: 5px;
    right: 5px;

    width: 2em;
    height: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    background:rgb(140, 216, 25);
    
    color: white;
    font-weight: 600;
    font-size: 1em;

    border: none;

    transform-style: preserve-3d;

    box-shadow: 1px 2px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    overflow: hidden;
    transition: background-color 150ms ease-in-out;
}

.CardButton_ButtonFlip__1iKnW {
    animation: CardButton_flip__2TCis 250ms ease-in-out forwards;
}

.CardButton_ButtonPlus__25SZe:focus,
.CardButton_ButtonMinus__13y8t:focus {
    outline: none;
    background: rgb(92, 203, 255);
}

.CardButton_ButtonPlus__25SZe:active,
.CardButton_ButtonMinus__13y8t:active {
    transform: translateY(1px);
    background:rgb(140, 216, 25);
    box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.CardButton_ButtonPlus__25SZe {
    border-radius: 3px 50% 50%;
}

.CardButton_ButtonMinus__13y8t {
    border-radius: 50% 50% 3px;
}

.CardButton_ButtonPlus__25SZe::before,
.CardButton_ButtonMinus__13y8t::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background: rgba(211, 255, 14, 0.1);
    transform: skew(45deg) translateX(-35%);
}

@keyframes CardButton_flip__2TCis {
    0%   { transform: scale(1); }
    50%  { transform: scale(0.5);}
    100% { transform: scale(1); }
}
.ImageContainer_ImageContainer__2jgjQ {
    position: relative;
    width: 95vw;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImageContainer_ImageLarge__1tH09 {
    position: relative;
    width: 95%;
}

@media (min-width: 550px) {
    .ImageContainer_ImageContainer__2jgjQ {
        width: 75vw;
        height: 95vh;
    }
}

@media (orientation: landscape) {
    .ImageContainer_ImageLarge__1tH09 {
        height: 100%;
        max-height: 100vh;
        width: auto;
    }
}
.Spinner_Spinner__1VElG,
.Spinner_Spinner__1VElG:before,
.Spinner_Spinner__1VElG:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation-fill-mode: both;
  animation: Spinner_load7__G__mS 1.8s infinite ease-in-out;
}
.Spinner_Spinner__1VElG {
  color: rgb(140, 216, 25);
  font-size: 10px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.Spinner_Spinner__1VElG:before,
.Spinner_Spinner__1VElG:after {
  content: '';
  position: absolute;
  top: 0;
}
.Spinner_Spinner__1VElG:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.Spinner_Spinner__1VElG:after {
  left: 3.5em;
}
@keyframes Spinner_load7__G__mS {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.ContactScreen_ContactScreen__3Slv1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2em;
    z-index: 1;
}

.ContactScreen_Title__2I9Fo {
    font-family: 'Oswald', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    opacity: 0;
    animation: ContactScreen_show__2AojZ 300ms ease-in-out forwards;
}

.ContactScreen_Intro__1gerx {
    margin: 2em 0;
    opacity: 0;
    animation: ContactScreen_showBackwards__34-AX 300ms 50ms ease-in-out forwards;
}

.ContactScreen_SocialMedia__1yS1x {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    opacity: 0;
    animation: ContactScreen_show__2AojZ 300ms 200ms ease-in-out forwards;
}

.ContactScreen_LinkContainer__jnxy0 {
    margin: 0.3rem;
    position: relative;
}

.ContactScreen_LinkContainer__jnxy0 a {
    display: block;
    position: relative;
}

.ContactScreen_LinkContainer__jnxy0 a:focus {
    outline: none;
    z-index: 100;
}

.ContactScreen_LinkContainer__jnxy0 a:focus img {
    box-shadow: 
        1px 3px rgba(0, 0, 0, 0.144),
        0 0 0 0.5rem rgba(240,205,7,1),
        0 0 0 1.5rem rgb(143, 62, 0);
}

.ContactScreen_LinkContainer__jnxy0:hover img {
    animation: ContactScreen_boing__15RlE 1s ease-in-out forwards;
    -webkit-filter: brightness(1.5) contrast(80%);
            filter: brightness(1.5) contrast(80%);
}

.ContactScreen_SocialMedia__1yS1x img {
    max-width: 3rem;
    height: auto;
    border-radius: 50%;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.144);
}

.ContactScreen_Phone__2FEcC,
.ContactScreen_Intro__1gerx a {
    color: #008d66;
    position: relative;
}

.ContactScreen_Intro__1gerx a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 8px;
    height: 8px;
    padding: 0;
    border: solid #008d66;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -250%) rotate(45deg);
    opacity: 0;
    transition: 
        transform 100ms ease-in-out,
        opacity 100ms ease-in-out;
}

.ContactScreen_Intro__1gerx a:hover::before {
    transform: translate(-50%, -150%) rotate(45deg);
    opacity: 1;
}

@keyframes ContactScreen_show__2AojZ {
    0%   {transform: translateX(-5%); opacity: 0;}
    100% {transform: translateX(0); opacity: 1;}
}

@keyframes ContactScreen_showBackwards__34-AX {
    0%   {transform: translateX(5%); opacity: 0;}
    100% {transform: translateX(0); opacity: 1;}
}

@keyframes ContactScreen_boing__15RlE {
    0%   { transform: scale(1); }
    10%  { transform: scale(0.6); }
    25%  { transform: scale(1.2); }
    50%  { transform: scale(0.85); }
    75%  { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@media (min-width: 550px) {
    .ContactScreen_ContactScreen__3Slv1 {
        align-items: center;
        padding: 1.5em clamp(4em, 15vw, 20em);
    }

    .ContactScreen_Intro__1gerx {
        margin: 2em clamp(2em, 8vw, 20em);
    }
}
.SmallSpinner_SmallSpinner__pwWuW {
    border: 5px solid #000000;
    border-top: 5px solid rgb(140, 216, 25);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: SmallSpinner_spin__3AbEn 2s linear infinite;

    background: transparent;
  }
  
  @keyframes SmallSpinner_spin__3AbEn {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.NotFoundScreen_NotFoundScreen__3TsOl {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2em;
    z-index: 1;
}

.NotFoundScreen_Title__2gqi_ {
    font-family: 'Oswald', sans-serif;
    font-size: 7em;
    text-transform: uppercase;
}

.NotFoundScreen_Content__28SQO {
    margin: 2em 0;
}

@media (min-width: 550px) {
    .NotFoundScreen_NotFoundScreen__3TsOl {
        align-items: center;
        padding: 1.5em 20vw;
    }
}

@media (min-width: 800px) {
    .NotFoundScreen_NotFoundScreen__3TsOl {
        padding: 1.5em 30vw;
    }
}

@media (min-width: 1200px) {
    .NotFoundScreen_NotFoundScreen__3TsOl {
        padding: 1.5em 40vw;
    }
}
.SkipNavigation_SkipNavigation__RSbSd {
    position: absolute;
    top: 0;
    left: 0;

    padding: 1rem;
    background: black;
    color: white;

    transform: translateY(-100%);

    transition: transform 150ms ease-in-out;

    border-radius: 0 0 5px 0;
}

.SkipNavigation_SkipNavigation__RSbSd:focus {
    outline: none;
    box-shadow: 0 0 0 3px white;
}

.SkipNavigation_In__c-HIK {
    transform: translateY(0);
}

.NotFoundScreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2em;
    z-index: 1;
}

.Title {
    font-family: 'Oswald', sans-serif;
    font-size: 7em;
    text-transform: uppercase;
}

.Content {
    margin: 2em 0;
}

@media (min-width: 550px) {
    .NotFoundScreen {
        align-items: center;
        padding: 1.5em 20vw;
    }
}

@media (min-width: 800px) {
    .NotFoundScreen {
        padding: 1.5em 30vw;
    }
}

@media (min-width: 1200px) {
    .NotFoundScreen {
        padding: 1.5em 40vw;
    }
}
.LanguageButton {
    position: relative;

    padding: 0.3rem;

    margin: 0.25rem;

    width: 30px;
    height: 30px;
    width: clamp(25px, 3vw, 40px);
    height: clamp(25px, 3vw, 40px);

    border-radius: 50%;
    border: none;

    font-size: 0.65em;
    font-variant: small-caps;

    cursor: pointer;

    background-color: rgb(255, 243, 188);

    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

    transition: box-shadow 100ms linear;
}

.LanguageButton:focus,
.LanguageButton:active {
    outline: none;
}

.LanguageButton:focus {
    box-shadow: 
        1px 1px 3px rgba(0, 0, 0, 0.2),
        0 0 0 0.25rem rgb(97, 163, 21);
}

@media (min-width: 550px) {
    .LanguageButton {
        font-size: 0.7em;
    }
}
.CardContainer {
    position: relative;
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-bottom: 2rem;
}

.Card {
    background: white;

    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;

    position: relative;
    padding: 15em 5em;
    border-radius: 5px 5px 20px 5px;

    transition: transform 750ms cubic-bezier(1,-0.52,0,1.59);
    transform-style: preserve-3d;

    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.CardFlip {
    transform: rotateY(.5turn);
}

.CardFront,
.CardBack {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    padding: 2rem;

    background-color: white;
    color: black;

    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

.CardFront {
    border-radius: 5px 5px 20px 5px;
}

.CardBack {
    display: grid;
    grid-template-rows: 1fr min-content;
    grid-gap: 0.5rem;

    /* color: white;
    background-color: rgb(15, 141, 101); */
    border-radius: 5px 5px 5px 20px;
    transform: rotateY(.5turn);
}

.CardBackTechnologies {
    color: #8f8f8f;
}

.CardFront a,
.CardBack a {
    position: relative;
    display: block;
    color: rgb(255, 153, 0);
    text-decoration: none;
    padding-bottom: 0.5em;
    transition: transform 150ms ease-in-out;
}

.CardFront a::before,
.CardBack a::before {
    content: '→';
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-110%);
    opacity: 0;
    transition: 
        transform 150ms ease-in-out,
        opacity 150ms ease-in-out;
}

.CardFront a:hover,
.CardBack a:hover {
    transform: translateX(1.5rem);
    text-decoration: underline;
}

.CardFront a:focus,
.CardBack a:focus {
    outline: none;
    color: rgb(31, 109, 199);
    font-weight: 600;
}

.CardFront a:hover::before,
.CardBack a:hover::before {
    transform: translateX(-1.5rem);
    opacity: 1;
}

.CardImageButton {
    width: 100%;
    height: 50%;

    background-color: rgb(49, 49, 49);

    position: relative;

    margin-bottom: 1rem;

    border: none;
    border-radius: 5px;

    cursor: pointer;

    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);

    overflow: hidden;
}

.CardImageButton:focus {
    box-shadow: 0 0 0 5px black;
}

.CardImage {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;
}

.CardImageExpand {
    animation: pull 150ms linear forwards;
}

.CardImageContract {
    animation: pull 150ms linear backwards;
}

.CardFrontTitle {
    font-family: 'Oswald', sans-serif;
    margin: 0 0 0.5em 0;
}

.CardFrontTitle,
.CardFrontText,
.CardFrontLink,
.CardImageButton,
.CardBack p,
.CardBack button {
    transform: translateZ(5rem);
}

@keyframes pull {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-100%); }
}

@media (min-width: 550px) {
    .Card {
        padding: 10em 5em;
    }

    .CardContainer {
        margin: 1rem;
    }
}
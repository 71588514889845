.NavButtons {
    position: fixed;

    bottom: 15px;
    right: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;

    z-index: 1000;

    transition:
        transform 300ms ease-in-out,
        opacity 300ms ease-in-out;
}

.NavButtonsOut {
    transform: translateY(150%);
    opacity: 0;
}

.NavButtonsIn {
    transform: translateY(0);
    opacity: 1;
}

@media (min-width: 720px) and (min-height: 650px) {
    .NavButtons {
        bottom: unset;
        top: clamp(5px, 3vh, 20px);
        right: 50%;

        flex-direction: column;
        gap: 1rem;
        align-items: center;

        transform: translateX(50%);
    }

    .NavButtonsOut {
        transform: translateX(50%) translateY(-150%);
    }
    
    .NavButtonsIn {
        transform: translateX(50%) translateY(0);
    }
}
.ContactScreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2em;
    z-index: 1;
}

.Title {
    font-family: 'Oswald', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    opacity: 0;
    animation: show 300ms ease-in-out forwards;
}

.Intro {
    margin: 2em 0;
    opacity: 0;
    animation: showBackwards 300ms 50ms ease-in-out forwards;
}

.SocialMedia {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    opacity: 0;
    animation: show 300ms 200ms ease-in-out forwards;
}

.LinkContainer {
    margin: 0.3rem;
    position: relative;
}

.LinkContainer a {
    display: block;
    position: relative;
}

.LinkContainer a:focus {
    outline: none;
    z-index: 100;
}

.LinkContainer a:focus img {
    box-shadow: 
        1px 3px rgba(0, 0, 0, 0.144),
        0 0 0 0.5rem rgba(240,205,7,1),
        0 0 0 1.5rem rgb(143, 62, 0);
}

.LinkContainer:hover img {
    animation: boing 1s ease-in-out forwards;
    filter: brightness(1.5) contrast(80%);
}

.SocialMedia img {
    max-width: 3rem;
    height: auto;
    border-radius: 50%;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.144);
}

.Phone,
.Intro a {
    color: #008d66;
    position: relative;
}

.Intro a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 8px;
    height: 8px;
    padding: 0;
    border: solid #008d66;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -250%) rotate(45deg);
    opacity: 0;
    transition: 
        transform 100ms ease-in-out,
        opacity 100ms ease-in-out;
}

.Intro a:hover::before {
    transform: translate(-50%, -150%) rotate(45deg);
    opacity: 1;
}

@keyframes show {
    0%   {transform: translateX(-5%); opacity: 0;}
    100% {transform: translateX(0); opacity: 1;}
}

@keyframes showBackwards {
    0%   {transform: translateX(5%); opacity: 0;}
    100% {transform: translateX(0); opacity: 1;}
}

@keyframes boing {
    0%   { transform: scale(1); }
    10%  { transform: scale(0.6); }
    25%  { transform: scale(1.2); }
    50%  { transform: scale(0.85); }
    75%  { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@media (min-width: 550px) {
    .ContactScreen {
        align-items: center;
        padding: 1.5em clamp(4em, 15vw, 20em);
    }

    .Intro {
        margin: 2em clamp(2em, 8vw, 20em);
    }
}